import { AddCircleOutline, Delete, Interests, Person } from '@mui/icons-material'
import { Avatar, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import React, { useEffect, useState } from 'react'
import { Link, Route, Routes } from "react-router-dom"
import userStore from 'store/userStore'
import LoadingList from 'myComponent/LoadingList'
import TambahPj from './TambahPj'
import Detail from './Detail'
import TambahST from './TambahST'
import { getDataWithResponse } from 'global/getFunc'
import Swal from 'sweetalert2'
import { postDelete } from 'global/postFunc'
import { blue, red } from '@mui/material/colors'

const SuaraTambahan = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
            <Route exact path='tambah-pj' element={<TambahPj />} />
            <Route exact path='tambah-st' element={<TambahST />} />
            {/* <Route exact path='edit' element={<Edit />} /> */}
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(true)
    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false);

    useEffect(() => {
        requestData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting])

    const requestData = () => {
        getDataWithResponse(
            '/entry/suara-tambahan/pj',
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const deleteData = (params) => {
        Swal.fire({
            title: 'Hapus Vote Getter?',
            icon: 'question',
            text: "Data Pemilih yang direkrut akan Otomatis Terhapus",
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
            confirmButtonColor: '#dd3333',
            // cancelButtonColor: '#d33',
        }).then((result) => {
            if (result.isConfirmed) {
                postDelete(
                    '/entry/suara-tambahan/delete-pj',
                    {
                        _method: 'DELETE',
                        id: params
                    },
                    decryptedUser,
                    setLoading,
                    () => {setDeleting(!deleting)},
                    logOut
                )
            }
        })
    }

    return (
        <>
            <Headerx title='Suara Tambahan' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={6}>
                        <Card >
                            <CardHeader
                                title={
                                    <Typography>
                                        Penanggung Jawab
                                    </Typography>
                                }
                                action={
                                    <Button
                                        variant="contained"
                                        endIcon={<AddCircleOutline color='white' />}
                                        // onClick={() => handleOpenDialog2()}
                                        to="/entry/suara-tambahan/tambah-pj"
                                        component={Link}
                                        // disabled={tableData.length >= 10}
                                    >
                                        Tambah
                                    </Button>
                                }
                            />

                            {
                                loading ?
                                    (
                                        <>
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                            <LoadingList />
                                            <Divider />
                                        </>
                                    )
                                    :
                                    tableData.length > 0 ?
                                        (
                                            <Grid container justifyContent="center" p={2}>
                                                <Grid item xl={12}>
                                                    <List sx={{ bgcolor: 'background.paper' }}>
                                                        {
                                                            tableData.map((data) => (
                                                                <React.Fragment key={data.id}>
                                                                    <ListItem
                                                                        alignItems="flex-start"
                                                                        secondaryAction={
                                                                            <IconButton key={1} edge="end" aria-label="delete" onClick={() => deleteData(data.id)}>
                                                                                <Delete color='error' />
                                                                            </IconButton>

                                                                        }
                                                                    >
                                                                        <ListItemButton
                                                                            component={Link} to="/entry/suara-tambahan/detail" state={data}
                                                                        >
                                                                            <ListItemAvatar>
                                                                                <Avatar sx={{bgcolor: blue[500]}}>
                                                                                    <Person />
                                                                                </Avatar>
                                                                            </ListItemAvatar>
                                                                            <ListItemText
                                                                                primary={
                                                                                    <Typography
                                                                                        variant="body1"
                                                                                        color="text.primary"
                                                                                    >
                                                                                        {data.nama}
                                                                                    </Typography>
                                                                                }
                                                                                secondary={
                                                                                    <React.Fragment>
                                                                                        <Typography
                                                                                            sx={{ display: 'inline' }}
                                                                                            component="span"
                                                                                            variant="subtitle2"
                                                                                            color="text.disabled"
                                                                                        >
                                                                                            {data.nik ? 'Nik. '+ data.nik : 'No. HP ' + data.no_hp }
                                                                                        </Typography>
                                                                                    </React.Fragment>

                                                                                }
                                                                            />
                                                                        </ListItemButton>
                                                                    </ListItem>
                                                                    <Divider />
                                                                </React.Fragment>
                                                            ))
                                                        }
                                                    </List>
                                                </Grid>
                                            </Grid>
                                        )
                                        :
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                Tidak ada data
                                            </Typography>
                                        </CardContent>

                            }
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default SuaraTambahan