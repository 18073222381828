import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Avatar, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Modal, Tooltip } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import React, { useEffect, useState } from 'react'
import userStore from 'store/userStore'

const Tambah = ({ text, data, dtdNama, deleting, setDeleting, ...props }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(false)

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [formData, setFormData] = useState({
        nama: '',
        nik: '',
        nama_tps: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: data.id_kel,
        nama_kel: data.nama_kel,
        dtd_username: data.dtd_username,
        dtd_nama: dtdNama
    })

    useEffect(() => {
        console.log(data);
        console.log(data.dtd_username);
        console.log(dtdNama);
        setFormData({
            ...formData,
            nama: '',
            nik: '',
            nama_tps: '',
            id_kec: data.id_kec,
            nama_kec: data.nama_kec,
            id_kel: data.id_kel,
            nama_kel: data.nama_kel,
            dtd_username: data.dtd_username,
            dtd_nama: dtdNama
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    // const dpts  = await axios.get('/dpt');

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/dtd/insert',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        )
    }

    const afterSubmit = () => {
        setDeleting(!deleting)
        handleClose()
        setFormData({})
    }

    return (
        <>
            <Button onClick={handleOpen} {...props}>Tambah</Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                sx={{
                    overflow: 'scroll',
                }}
            >
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={handleClose}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container justifyContent='center' pb={2}>
                                                <Grid item xs={12}>
                                                    <Alert severity="error">
                                                        <SoftTypography component="h6" variant="h3" fontWeight="bold">
                                                            Penting
                                                        </SoftTypography>
                                                        <List>
                                                            <ListItem disablePadding>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{ bgcolor: deepOrange[500] }}>1</Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary="Bagi Pemilih yang tidak ada NIK, maka NIK dikosongkan" />
                                                            </ListItem>
                                                            <ListItem disablePadding>
                                                                <ListItemAvatar>
                                                                    <Avatar sx={{ bgcolor: deepOrange[500] }}>2</Avatar>
                                                                </ListItemAvatar>
                                                                <ListItemText primary="Bagi Pemilih yang memiliki NIK, tetapi NIK tidak terdaftar di DPT online atau merupakan pemilih diluar Kolaka Utara maka NIK diinput dengan menambahkan tanda X. contoh 740101234567890X" />
                                                            </ListItem>
                                                        </List>
                                                    </Alert>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                NIK
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nik}
                                                            onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama}
                                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                TPS
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_tps}
                                                            onChange={(event) => setFormData({ ...formData, nama_tps: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center' pt={3}>
                                                <Grid item xs={10}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </Modal>
        </>
    )
}

export default Tambah