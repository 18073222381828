import { Card, CardContent, CardHeader, FormControl, Grid, MenuItem, Select, Typography } from '@mui/material'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import userStore from 'store/userStore'
import Headerx from 'components/Header/Headerx'
import SelectKec from 'myComponent/SelectKec'
import SelectDesa from 'myComponent/SelectDesa'
import MyTable from 'myComponent/Table/MyTable'
import { postDataWithResponse } from 'global/postFunc'
import SoftTypography from 'components/SoftTypography'
import { AddCircleOutline } from '@mui/icons-material'
import Tambah from './Tambah'

const DTD = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const locationState = location.state

    const [tableData, setTableData] = useState([]);
    const [deleting, setDeleting] = useState(false)

    const [loading, setLoading] = useState(true)

    const [formData, setFormData] = useState({
        id_kec: locationState !== null ? locationState : '',
        id_kel: '',
        dtd_username: ''
    })

    const [listRelawan, setListRelawan] = useState([])
    const [relawan, setRelawan] = useState('')
    const [listVg, setListVg] = useState([])
    const [vg, setVg] = useState('')

    useEffect(() => {
        requestData()
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deleting, formData])

    useEffect(() => {
        reqRelawan(formData.id_kel)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    useEffect(() => {
        reqVg(relawan)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [relawan])

    useEffect(() => {
        if (formData.id_kec === '') {
            setRelawan('');
            setListRelawan([])
        }
    }, [formData])

    const reqVg = (param) => {
        if (param === '') {
            setListVg([])
            setFormData({ ...formData, dtd_username: '' })
        } else {
            postDataWithResponse(
                '/entry/vote-getter',
                {
                    nik_rel: param
                },
                decryptedUser,
                setLoading,
                setListVg,
                logOut
            )
        }
    }

    const reqRelawan = (param) => {
        if (param === '') {
            setListRelawan([])
            setRelawan('')
        }
        else {
            postDataWithResponse(
                '/entry/korkel',
                {
                    id_kel: param
                },
                decryptedUser,
                setLoading,
                setListRelawan,
                logOut
            )
        }
    }

    const requestData = () => {
        postDataWithResponse(
            '/entry/dtd',
            formData,
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const handleChangeRel = (event) => {
        setRelawan(event.target.value)
    };

    const handleChangeVg = (event) => {
        setFormData({ ...formData, dtd_username: event.target.value })
        if (event.target.value === '') {
            setVg('')
        }
        else {
            let resultObject = listVg.find(x => x.nik === event.target.value)
            setVg(resultObject.nama)
        }

    };

    const columns = [
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama'
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
        },
        {
            accessorKey: 'nama_tps',
            header: 'TPS'
        },
        {
            accessorKey: 'dtd_nama',
            header: 'VG'
        },
    ]

    const columnVisibility = {
        no_kk: false,
        id_kec: false,
        id_kel: false,
    }

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <Typography>
                                        Tabel Pendataan
                                    </Typography>
                                }
                                action={
                                    <Tambah
                                        endIcon={<AddCircleOutline color='white' />}
                                        variant="contained"
                                        disabled={formData.dtd_username === ''}
                                        data={formData}
                                        dtdNama={vg}
                                        deleting={deleting}
                                        setDeleting={setDeleting}
                                    />
                                }
                            />

                            <CardContent>
                                <Grid container spacing={2} mb={2}>
                                    <Grid item xs={12} lg={3}>
                                        <SelectKec formData={formData} setFormData={setFormData} />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} />
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Pilih Relawan
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue=''
                                                value={relawan}
                                                label="Relawan"
                                                onChange={handleChangeRel}
                                                required
                                            >
                                                <MenuItem value=''><em>Pilih Relawan</em></MenuItem>
                                                {
                                                    listRelawan.map((rel) => (
                                                        <MenuItem key={rel.nik} value={rel.nik}>{rel.nama}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} lg={3}>
                                        <FormControl fullWidth>
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Pilih Vote Getter
                                                </SoftTypography>
                                            </SoftBox>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                defaultValue=''
                                                value={formData.dtd_username}
                                                label="Vote Getter"
                                                onChange={handleChangeVg}
                                                required
                                            >
                                                <MenuItem value=''><em>Pilih Vote Getter</em></MenuItem>
                                                {
                                                    listVg.map((vg) => (
                                                        <MenuItem key={vg.nik} value={vg.nik}>{vg.nama}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <MyTable
                                            data={tableData ?? []}
                                            columns={columns}
                                            deleting={deleting}
                                            setDeleting={setDeleting}
                                            columnVisibility={columnVisibility}
                                            deleteUrl={serverUrl + '/entry/dtd/delete'}
                                            deleteField='nik'
                                            detailUrl={'/detail'}
                                            state={{ isLoading: loading }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default DTD