import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, Tooltip, Typography } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const TambahVg = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const [loading, setLoading] = useState(false)

    const location = useLocation();
    const data = location.state;

    const [formData, setFormData] = useState({
        nama: '',
        nik: '',
        no_hp: '',
        nama_tps: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: data.id_kel,
        nama_kel: data.nama_kel,
        nik_rel: data.nik,
        nama_rel: data.nama
    })

    const submit = (event) => {
        event.preventDefault();

        postSubmit(
            '/entry/vote-getter/insert',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        );
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} mt={3} justifyContent='center'>
                    <Grid item xs={12} lg={9}>
                        <Card>
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                Kelurahan
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nama_kel}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                NIK Relawan
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nik}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                Nama Relawan
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nama}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' py={2}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Alert severity="error">
                            <SoftTypography component="h6" variant="h3" fontWeight="bold">
                                Penting
                            </SoftTypography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: deepOrange[500] }}>1</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Bagi Vote Getter yang tidak ada NIK, maka NIK diganti menjadi "vg_nama_desa_angka". Contoh vg_lambuno_1' />
                                </ListItem>
                                <ListItem disablePadding sx={{ pt: 2 }}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: deepOrange[500] }}>2</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Bagi Vote Getter yang memiliki NIK, tetapi NIK tidak terdaftar di DPT online atau merupakan pemilih diluar Kolaka Utara maka NIK diinput dengan menambahkan tanda X. contoh 740101234567890X" />
                                </ListItem>
                            </List>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardContent component='form' onSubmit={submit}>
                                <Grid container justifyContent='center' direction='column'>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Kecamatan
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama_kec}
                                                // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                autoComplete='off'
                                                disabled
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Kelurahan
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama_kel}
                                                // onChange={(event) => setFormData({ ...formData, username: event.target.value })}
                                                autoComplete='off'
                                                disabled
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    NIK
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nik}
                                                onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                                autoComplete='off'
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Nama
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama}
                                                onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                autoComplete='off'
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    TPS
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama_tps}
                                                onChange={(event) => setFormData({ ...formData, nama_tps: event.target.value })}
                                                autoComplete='off'
                                                required
                                                inputProps={{ maxLength: 12 }}
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    No. HP
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.no_hp}
                                                onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                autoComplete='off'
                                                required
                                                inputProps={{ maxLength: 12 }}
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <Grid container justifyContent='flex-end'>
                                            <Grid item>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <LoadingButton
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        startIcon={<Save />}
                                                        variant="contained"
                                                        type='submit'
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahVg