import { ArrowBack, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Box, Card, CardContent, CardHeader, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, ListSubheader, MenuItem, Select, TextField, Tooltip, Typography } from '@mui/material';
import axios from 'axios';
import Headerx from 'components/Header/Headerx';
import SoftBox from 'components/SoftBox';
import SoftInput from 'components/SoftInput';
import SoftTypography from 'components/SoftTypography';
import { CustomDescrypt } from 'global/func';
import { postSubmit } from 'global/postFunc';
import { postDataWithResponse } from 'global/postFunc';
import { serverUrl } from 'global/variable';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import userStore from 'store/userStore';

const TambahST = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false)

    const [desas, setDesas] = useState([]);
    const [kecs, setKecs] = useState([]);
    // const [desa, setDesa] = useState('');
    // const [kec, setKec] = useState('');

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: '',
        nama_kel: '',
        nama_tps: '',
        suara_tambahan: data.id,
        suara_tambahan_nama: data.nama
    })

    useEffect(() => {
        console.log(formData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
    }

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/suara-tambahan/insert',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        );
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kel: '', nama_kel: '' })
        }
        else {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            console.log(resultObject);
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa })
        }
    };

    const handleChangeKec = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kec: '', nama_kec: '' })
        }
        else {
            let resultObject = kecs.find(x => x.id_kec === event.target.value)
            setFormData({ ...formData, id_kec: event.target.value, nama_kec: resultObject.nama_kec })
            getDesa(event.target.value)
        }
    };

    useEffect(() => {
        getKec()
    }, [])

    const getKec = async () => {
        const kec = await axios.get(serverUrl + '/kecamatan');

        setKecs(kec.data)
    }

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} justifyContent='center' py={5}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            {
                                loading ?
                                    (
                                        <Grid container spacing={3} py={5} justifyContent="center">
                                            <Grid item>
                                                <Box sx={{ display: 'flex' }}>
                                                    <CircularProgress />
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    )
                                    :
                                    (
                                        <CardContent component='form' onSubmit={submit}>
                                            <Grid container spacing={3} justifyContent="center">
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kecamatan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={formData.id_kec}
                                                        label="Kecamatan"
                                                        onChange={handleChangeKec}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            kecs.map((kec) => (
                                                                <MenuItem key={kec.id_kec} value={kec.id_kec}>{kec.nama_kec}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={1} ml={0.5}>
                                                        <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                            Pilih Kelurahan
                                                        </SoftTypography>
                                                    </SoftBox>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        defaultValue=''
                                                        value={formData.id_kel}
                                                        label="Desa"
                                                        onChange={handleChangeDesa}
                                                        required
                                                    >
                                                        <MenuItem value=''><em>Pilih Desa</em></MenuItem>
                                                        {
                                                            desas.map((des) => (
                                                                <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                            ))
                                                        }
                                                    </Select>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                NIK
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nik}
                                                            onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                                            autoComplete='off'
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                Nama
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama}
                                                            onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <SoftBox mb={2} justifyContent="center">
                                                        <SoftBox mb={1} ml={0.5}>
                                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                                TPS
                                                            </SoftTypography>
                                                        </SoftBox>
                                                        <SoftInput
                                                            type="text"
                                                            defaultValue={formData.nama_tps}
                                                            onChange={(event) => setFormData({ ...formData, nama_tps: event.target.value })}
                                                            autoComplete='off'
                                                            required
                                                            inputProps={{ maxLength: 12 }}
                                                        />
                                                    </SoftBox>
                                                </Grid>
                                            </Grid>
                                            <Grid container justifyContent='center'>
                                                <Grid item xs={12} sm={12} lg={6}>
                                                    <Grid container justifyContent='flex-end'>
                                                        <Grid item>
                                                            <SoftBox mb={1} ml={0.5}>
                                                                <LoadingButton
                                                                    loading={loading}
                                                                    loadingPosition="start"
                                                                    startIcon={<Save />}
                                                                    variant="contained"
                                                                    type='submit'
                                                                >
                                                                    Save
                                                                </LoadingButton>
                                                            </SoftBox>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    )
                            }

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahST