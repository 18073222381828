import { Delete, Edit, FileDownload, Info } from '@mui/icons-material'
import { Box, Button, ListItemIcon, MenuItem, Paper, TableContainer } from '@mui/material'
import axios from 'axios';
import { MaterialReactTable } from 'material-react-table'
import { Link, useLocation } from 'react-router-dom';
import userStore from 'store/userStore';
import Swal from 'sweetalert2';
import { ExportToCsv } from 'export-to-csv'; //or use your library of choice here
import { CustomDescrypt } from 'global/func';

const MyTable = ({ data = [], columns, deleting, setDeleting, deleteUrl = null, deleteField, detailUrl = null, editUrl = null, columnVisibility = null, ...props }) => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const pathname = useLocation().pathname;

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        // headers: columns.map((c) => c.header),
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const hapusData = (field, key, url) => {
        console.log(key);
        const frm = {}
        frm[field] = key;
        frm['_method'] = 'DELETE'
        Swal.fire({
            title: 'Hapus Data?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Hapus',
            cancelButtonText: 'Batal',
        }).then((result) => {
            if (result.isConfirmed) {
                axios.post(
                    url,
                    frm,
                    {
                        headers: {
                            'Authorization': `Bearer ${decryptedUser}`,
                            'content-type': 'multipart/form-data'
                        }
                    })
                    .then((response) => {
                        console.log(response);
                        Swal.fire('Berhasil!', response.data, 'success');
                        setDeleting(!deleting)
                    })
                    .catch((error) => {
                        // console.log(error);
                        if (error.response.status === 401 && error.response.data.messages === "Access Token Expired") {
                            logOut();
                        }
                        else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error.response.data.messages.msg
                            })
                        }
                        // setTableLoading(false)
                    })
            }
        })
    }

    const handleExportData = () => {
        csvExporter.generateCsv(data);
    };

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original));
    };

    return (
        <TableContainer component={Paper}>
            <MaterialReactTable
                // elevation={0}
                // variant='outlined'
                data={data}
                columns={columns}
                enableColumnResizing
                initialState={{
                    showColumnFilters: true,
                    columnVisibility: columnVisibility,
                    // pagination: { pageSize: 10, pageIndex:1 }
                }}
                enableRowActions={deleteUrl !== null || detailUrl !== null || editUrl !== null}
                // onColumnFiltersChange={setColumnFilters} 
                positionActionsColumn='first'
                displayColumnDefOptions={{
                    'mrt-row-actions': {
                        header: '', //change header text
                        size: 50, //make actions column wider
                        alignment: 'left'
                    },
                }}
                renderRowActionMenuItems={({ row }) => [
                    detailUrl && 
                    <MenuItem
                        key={0}
                        component={Link} to={pathname + detailUrl} state={row.original}
                        sx={{ m: 0 }}
                        disabled={detailUrl === null}
                    >
                        <ListItemIcon>
                            <Info color={detailUrl ? 'info' : 'disabled'} />
                        </ListItemIcon>
                        Detail
                    </MenuItem>,
                    editUrl &&
                    <MenuItem
                        key={1}
                        component={Link} to={pathname + editUrl} state={row.original}
                        sx={{ m: 0 }}
                        disabled={editUrl === null}
                    >
                        <ListItemIcon>
                            <Edit color={editUrl ? 'primary' : 'disabled'} />
                        </ListItemIcon>
                        Edit
                    </MenuItem>,
                    deleteUrl &&
                    <MenuItem
                        key={2}
                        onClick={() => {
                            // View profile logic...
                            hapusData(deleteField, row.original[deleteField], deleteUrl);
                        }}
                        sx={{ m: 0 }}
                        disabled={deleteUrl === null}
                    >
                        <ListItemIcon>
                            <Delete color={deleteUrl ? 'error' : 'disabled'} />
                        </ListItemIcon>
                        Hapus
                    </MenuItem>,
                ]}

                muiTablePaginationProps={{
                    rowsPerPageOptions: [10, 20, 50, { label: 'All', value: data.length }],
                    showFirstButton: true,
                    showLastButton: true,
                }}
                {...props}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        <Button
                            color="primary"
                            //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                            onClick={handleExportData}
                            startIcon={<FileDownload />}
                            variant="contained"
                        >
                            Export All
                        </Button>
                        <Button
                            disabled={table.getPrePaginationRowModel().rows.length === 0}
                            //export all rows, including from the next page, (still respects filtering and sorting)
                            onClick={() =>
                                handleExportRows(table.getPrePaginationRowModel().rows)
                            }
                            startIcon={<FileDownload />}
                            variant="contained"
                        >
                            Export Rows
                        </Button>
                    </Box>
                )}
            />
        </TableContainer>
    )
}

export default MyTable