import { ArrowBack, Save } from '@mui/icons-material'
import { LoadingButton } from '@mui/lab'
import { Alert, Avatar, Card, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Tooltip, Typography } from '@mui/material'
import { deepOrange } from '@mui/material/colors'
import axios from 'axios'
import Headerx from 'components/Header/Headerx'
import SoftBox from 'components/SoftBox'
import SoftInput from 'components/SoftInput'
import SoftTypography from 'components/SoftTypography'
import { CustomDescrypt } from 'global/func'
import { postSubmit } from 'global/postFunc'
import { serverUrl } from 'global/variable'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import userStore from 'store/userStore'

const TambahRelawan = () => {
    const navigate = useNavigate();
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const data = location.state;

    const [loading, setLoading] = useState(false)
    const [desas, setDesas] = useState([]);

    const [formData, setFormData] = useState({
        nik: '',
        nama: '',
        no_hp: '',
        id_kec: data.id_kec,
        nama_kec: data.nama_kec,
        id_kel: '',
        nama_kel: '',
        nama_tps: ''
    })

    const submit = (event) => {
        event.preventDefault();
        postSubmit(
            '/entry/korkel/insert',
            formData,
            decryptedUser,
            setLoading,
            afterSubmit,
            logOut
        );
    }

    const afterSubmit = () => {
        navigate(-1)
    }

    useEffect(() => {
        console.log(data);
        getDesa(data.id_kec)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getDesa = async (params) => {
        const data = await axios.get(serverUrl + '/desa/' + params);
        setDesas(data.data)
        // console.log(data);
    }

    const handleChangeDesa = (event) => {
        if (event.target.value === '') {
            setFormData({ ...formData, id_kel: '', nama_kel: '' })
        }
        else {
            let resultObject = desas.find(x => x.id_desa === event.target.value)
            console.log(resultObject);
            setFormData({ ...formData, id_kel: event.target.value, nama_kel: resultObject.nama_desa })
        }
    };

    useEffect(() => {
        console.log(formData);
    }, [formData])

    return (
        <>
            <Headerx title='Tambah Data' />
            <SoftBox mb={3}>
                <Grid container spacing={3} mt={3} justifyContent='center'>
                    <Grid item xs={12} lg={9}>
                        <Card>
                            <CardHeader
                                title={
                                    <SoftTypography variant='h6'>Kembali</SoftTypography>
                                }
                                avatar={
                                    <Tooltip title="Kembali">
                                        <IconButton variant="outlined" onClick={() => navigate(-1)}><ArrowBack /></IconButton>
                                    </Tooltip>
                                }
                            />
                            <CardContent>
                                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                Kecamatan
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nama_kec}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                NIK Korcam
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nik}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant='subtitle2'>
                                                Nama Korcam
                                            </Typography>
                                        } secondary={
                                            <Typography variant='body2'>
                                                {data.nama}
                                            </Typography>
                                        } />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </List>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' py={2}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Alert severity="error">
                            <SoftTypography component="h6" variant="h3" fontWeight="bold">
                                Penting
                            </SoftTypography>
                            <List>
                                <ListItem disablePadding>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: deepOrange[500] }}>1</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary='Bagi Korder yang tidak ada NIK, maka NIK diganti menjadi "kordes_nama_desa". Contoh kordes_lambuno' />
                                </ListItem>
                                <ListItem disablePadding sx={{ pt: 2 }}>
                                    <ListItemAvatar>
                                        <Avatar sx={{ bgcolor: deepOrange[500] }}>2</Avatar>
                                    </ListItemAvatar>
                                    <ListItemText primary="Bagi Kordes yang memiliki NIK, tetapi NIK tidak terdaftar di DPT online atau merupakan pemilih diluar Kolaka Utara maka NIK diinput dengan menambahkan tanda X. contoh 740101234567890X" />
                                </ListItem>
                            </List>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid container spacing={3} justifyContent='center' pb={3}>
                    <Grid item xs={12} sm={12} md={9}>
                        <Card >
                            <CardContent component='form' onSubmit={submit}>
                                <Grid container justifyContent='center' direction='column'>
                                    <Grid item xs={12} sm={12} lg={6} mb={2}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                Desa / Kelurahan
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            defaultValue=''
                                            value={formData.id_kel || ''}
                                            label="Desa"
                                            onChange={handleChangeDesa}
                                            required
                                        >
                                            <MenuItem value=''><em>Pilih Desa / Kelurahan</em></MenuItem>
                                            {
                                                desas.map((des) => (
                                                    <MenuItem key={des.id_desa} value={des.id_desa}>{des.nama_desa}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    NIK
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nik}
                                                onChange={(event) => setFormData({ ...formData, nik: event.target.value })}
                                                autoComplete='off'
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    Nama
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama}
                                                onChange={(event) => setFormData({ ...formData, nama: event.target.value })}
                                                autoComplete='off'
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    TPS
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.nama_tps}
                                                onChange={(event) => setFormData({ ...formData, nama_tps: event.target.value })}
                                                autoComplete='off'
                                                required
                                                inputProps={{ maxLength: 12 }}
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <SoftBox mb={2} justifyContent="center">
                                            <SoftBox mb={1} ml={0.5}>
                                                <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                    No. HP
                                                </SoftTypography>
                                            </SoftBox>
                                            <SoftInput
                                                type="text"
                                                defaultValue={formData.no_hp}
                                                onChange={(event) => setFormData({ ...formData, no_hp: event.target.value })}
                                                autoComplete='off'
                                                required
                                                inputProps={{ maxLength: 12 }}
                                            />
                                        </SoftBox>
                                    </Grid>
                                    <Grid item xs={12} sm={12} lg={6}>
                                        <Grid container justifyContent='flex-end'>
                                            <Grid item>
                                                <SoftBox mb={1} ml={0.5}>
                                                    <LoadingButton
                                                        loading={loading}
                                                        loadingPosition="start"
                                                        startIcon={<Save />}
                                                        variant="contained"
                                                        type='submit'
                                                    >
                                                        Save
                                                    </LoadingButton>
                                                </SoftBox>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default TambahRelawan