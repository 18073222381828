import { Card, CardContent, CardHeader, Chip, Grid, MenuItem, Select, Stack, Typography } from '@mui/material'
import axios from 'axios'
import MiniStatisticsCard from 'components/Cards/StatisticsCards/MiniStatisticsCard'
import SoftBox from 'components/SoftBox'
import { CustomDescrypt } from 'global/func'
import { serverUrl } from 'global/variable'
import React, { useEffect, useState } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import userStore from 'store/userStore'
import Headerx from 'components/Header/Headerx'
import SelectKec from 'myComponent/SelectKec'
import SelectDesa from 'myComponent/SelectDesa'
import MyTable from 'myComponent/Table/MyTable'
import Detail from './Detail'
import SoftTypography from 'components/SoftTypography'
import { postDataWithResponse } from 'global/postFunc'

const Pemilih = () => {
    return (
        <Routes>
            <Route exact path='' element={<Data />} />
            <Route exact path='detail' element={<Detail />} />
        </Routes>
    )
}

const Data = () => {
    const encryptUser = localStorage.getItem('AuthPersist');
    const encryptUserJson = JSON.parse(encryptUser);
    const decryptedUser = encryptUserJson.state.user === null ? "" : JSON.parse(CustomDescrypt(encryptUserJson.state.user));
    const logOut = userStore((state) => state.logOut)

    const location = useLocation();
    const locationState = location.state

    const [tableData, setTableData] = useState([]);
    const [listTps, setListTps] = useState([])

    const [loading, setLoading] = useState(true)

    const [formData, setFormData] = useState({
        id_kab: locationState !== null ? locationState : '',
        id_kec: '',
        id_kel: '',
        nama_tps: ''
    })

    useEffect(() => {
        requestData()
        formData.id_kel !== '' && reqTps(formData.id_kel)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    const reqTps = async (params) => {
        const data = await axios.get(serverUrl + '/tps/' + params);
        setListTps(data.data)
        // console.log(data);
    }

    const requestData = () => {
        postDataWithResponse(
            '/admin/pemilih',
            formData,
            decryptedUser,
            setLoading,
            setTableData,
            logOut
        )
    }

    const handleChangeTps = (event) => {
        setFormData({ ...formData, nama_tps: event.target.value })
    };

    const columns = [
        {
            accessorKey: 'no_kk',
            header: 'No KK',
            filterVariant: 'text',
        },
        {
            accessorKey: 'nik',
            header: 'NIK'
        },
        {
            accessorKey: 'nama',
            header: 'Nama',
            Cell: ({ cell }) => {
                switch (cell.row.original.tim) {
                    case 'Korcam':
                        return <Chip label={cell.row.original.nama} color='primary' />

                    case 'Relawan Brigade':
                        return <Chip label={cell.row.original.nama} color='success' />

                    case 'Vote Getter':
                        return <Chip label={cell.row.original.nama} color='warning' />

                    default:
                        switch (cell.row.original.suara_tambahan) {
                            case '':
                                return cell.row.original.nama

                            default:
                                return <Typography variant='body2' color='red'>{cell.row.original.nama}</Typography>
                        }
                }
            }
        },
        {
            accessorKey: 'id_kec',
            header: 'ID_KEC'
        },
        {
            accessorKey: 'nama_kec',
            header: 'Kec',
        },
        {
            accessorKey: 'id_kel',
            header: 'ID_KEL',
        },
        {
            accessorKey: 'nama_kel',
            header: 'Kel',
        },
        {
            accessorKey: 'nama_tps',
            header: 'TPS'
        },
        {
            accessorKey: 'tempat_lahir',
            header: 'Tmp Lahir'
        },
        {
            accessorKey: 'tgl_lahir',
            header: 'Tgl Lahir'
        }
    ]

    const columnVisibility = {
        no_kk: false,
        id_kec: false,
        id_kel: false,
        tempat_lahir: false,
        tgl_lahir: false,
        tim: false,
    }

    return (
        <>
            <Headerx title='Admin' />
            <SoftBox my={3}>
                <Grid container spacing={3} mb={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <MiniStatisticsCard
                            title={{ text: "Total Data" }}
                            count={tableData.length}
                            // percentage={{ color: "success", text: "+55%" }}
                            icon={{ color: "info", component: "paid" }}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={3} justifyContent='center'>
                    <Grid item xs={12} lg={12}>
                        <Card>
                            <CardHeader
                                title={
                                    <SoftTypography color='text'>
                                        Tabel Pemilih
                                    </SoftTypography>
                                }
                            />
                            <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <SoftBox mb={1} ml={0.5}>
                                            <Typography component="label" variant="body2" fontWeight="bold">
                                                Ket
                                            </Typography>
                                        </SoftBox>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Stack direction="row" spacing={1}>
                                            <Chip label="Korcam" color="primary" />
                                            <Chip label="Relawan Brigade" color="success" />
                                            <Chip label="Vote Getter" color="warning" />
                                            <Typography variant='body2' color='red'>Suara Tambahan</Typography>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                    {/* <Grid item xs={12} lg={4}>
                                        <SelectKab formData={formData} setFormData={setFormData} />
                                    </Grid> */}
                                    <Grid item xs={12} lg={4}>
                                        <SelectKec formData={formData} setFormData={setFormData} idKab={formData.id_kab} />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <SelectDesa formData={formData} setFormData={setFormData} idKec={formData.id_kec} />
                                    </Grid>
                                    <Grid item xs={12} lg={4}>
                                        <SoftBox mb={1} ml={0.5}>
                                            <SoftTypography component="label" variant="caption" fontWeight="bold">
                                                TPS
                                            </SoftTypography>
                                        </SoftBox>
                                        <Select
                                            fullWidth
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={formData.nama_tps}
                                            label="Kabupaten"
                                            onChange={handleChangeTps}
                                            required
                                        >
                                            <MenuItem value=''><em>Pilih TPS</em></MenuItem>
                                            {
                                                listTps.map((tps) => (
                                                    <MenuItem key={tps.nama_tps} value={tps.nama_tps}>TPS {tps.nama_tps}</MenuItem>
                                                ))
                                            }
                                        </Select>
                                    </Grid>
                                </Grid>
                                <Grid container justifyContent="space-between" >
                                    <Grid item xs={12}>
                                        <MyTable
                                            data={tableData ?? []}
                                            columns={columns}
                                            columnVisibility={columnVisibility}
                                            detailUrl={'/detail'}
                                            state={{ isLoading: loading }}
                                        />
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </SoftBox>
        </>
    )
}

export default Pemilih